import styled from "styled-components"
import Img from "gatsby-image"
import Arrow from "../../images/arrow-right.svg"
import {
  COLORS,
  FONTS,
  IPHONE5_MAX_WIDTH,
  IPAD_MINI_MAX_WIDTH,
  MAX_WIDTH_LAYOUT,
} from "../../styles"
import { Link } from "gatsby"
import { H4, S1, S2, T1, T4 } from "../common/text"
import { CTA } from "../common/cta"

export default {
  Container: styled.div`
    max-width: ${MAX_WIDTH_LAYOUT}px;
    margin: 0 auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    background-color: ${COLORS.PALE_GREY};
    padding: 50px 0px 50px 50px;
    @media screen and (max-width: ${IPAD_MINI_MAX_WIDTH}px) {
      padding: 30px 0px 20px 30px;
    }
    .slick-dots {
      position: static;
      margin-left: -10px;
      margin-top: -25px;
      text-align: left;
      @media screen and (max-width: ${IPAD_MINI_MAX_WIDTH}px) {
        margin-top: 0px;
        margin-bottom: 20px;
      }
    }
    .slick-dots li {
      margin-right: -13px;
      font-size: 7px;
    }

    .slick-dots li.slick-active button:before {
      color: ${COLORS.PINE_GREEN};
    }
    .slick-dots li button:before {
      color: ${COLORS.BLUE_CHALK_VIOLET};
    }
    .slick-slide {
      padding: 5px;
      z-index: 1;
    }
  `,
  Title: styled(S2)`
    position: absolute;
    margin-top: 2px;
  `,
  Itinerary: {
    Container: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      @media screen and (max-width: ${IPAD_MINI_MAX_WIDTH}px) {
        flex-direction: column;
      }
    `,
    Container_1: styled.div`
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      @media screen and (max-width: ${IPAD_MINI_MAX_WIDTH}px) {
        width: 100%;
        margin-right: 1px;
        margin-bottom: 30px;
      }
    `,
    Image: styled(Img)`
      object-fit: contain;
      width: 50%;
      height: 410px;
      @media screen and (max-width: ${IPAD_MINI_MAX_WIDTH}px) {
        align-self: flex-end;
        width: 100%;
        padding-right: 30px;
        height: 264px;
      }
    `,
    Title: styled(H4)`
      margin-bottom: 5px;
    `,
    Days: styled(S1)`
      width: fit-content;
      margin-top: 100px;
      padding: 8px 8px 6px 8px;
      background-color: ${COLORS.HOLLY_GREEN};
      margin-bottom: 15px;
      @media screen and (max-width: ${IPAD_MINI_MAX_WIDTH}px) {
        margin-top: 50px;
        padding: 6px 8px 6px 8px;
      }
    `,
    Creator: styled(S1)`
      margin-bottom: 15px;
    `,
    Description: styled(T1)`
      width: 327px;
      margin-bottom: 20px;
      @media screen and (max-width: ${IPHONE5_MAX_WIDTH}px) {
        width: 300px;
      }
    `,
    CTA: styled(CTA)``,
  },
}
