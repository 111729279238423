import React from "react"
import { Article } from "../../templates/news/news"

import Style from "./card.style"

interface Props {
  card: Article
  className?: string
}

const Card = ({ card, className }: Props) => {
  return (
    <Style.Container className={className}>
      {card.image && <Style.Image fluid={card.image.fluid} />}
      <Style.Title>{card.title}</Style.Title>
      <Style.Content>{card.content.content}</Style.Content>
      <Style.Link
        to={card.externalLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        Read more
      </Style.Link>
    </Style.Container>
  )
}

export default Card
