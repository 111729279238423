import styled from "styled-components"
import {
  COLORS,
  IPAD_MAX_WIDTH,
  Z_INDEXES,
  MOBILE_MAX_WIDTH,
} from "../../styles"
import ArrowIcon from "../../images/arrow.svg"
import Instagram from "../../images/instagram.svg"
import { H4, S1 } from "../common/text"

export default {
  Container: styled.div`
    align-items: center;
    background-color: ${COLORS.BEIGE};
    display: flex;
    flex-direction: column;
    padding: 3rem 0;
    justify-content: center;
    overflow: hidden;
  `,
  PostsContainer: styled.div`
    margin-top: 50px;
    width: 100%;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-top: 10px;
    }
  `,
  Post: styled.div`
    margin: 0.3rem;
  `,
  CarouselNextArrowWrapper: styled.div`
    && {
      width: 2rem;
      height: 4rem;
      background-color: ${COLORS.BOTTLE_GREEN};
      z-index: ${Z_INDEXES.INSTA_CAROUSEL_ARROW};
      /*
     * React-slick override the display to block
     * using inline css
     * so we need to use !important to override it
     */
      display: flex !important;
      justify-content: center;
      align-items: center;
      right: 0;
      /*
     * React-slick define a :before which add an icon by default
     */
      &:before {
        content: "";
      }
      /*
     * React-slick set the background to transparent by default
     */
      &:hover {
        background-color: ${COLORS.BOTTLE_GREEN};
      }
      @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
        display: none !important;
      }
    }
  `,
  CarouselPrevArrowWrapper: styled.div`
    && {
      width: 2rem;
      height: 4rem;
      background-color: ${COLORS.BOTTLE_GREEN};
      z-index: ${Z_INDEXES.INSTA_CAROUSEL_ARROW};
      /*
     * React-slick override the display to block
     * using inline css
     * so we need to use !important to override it
     */
      display: flex !important;
      justify-content: center;
      align-items: center;
      left: 0;
      /*
     * React-slick define a :before which add an icon by default
     */
      &:before {
        content: "";
      }
      /*
     * React-slick set the background to transparent by default
     */
      &:hover {
        background-color: ${COLORS.BOTTLE_GREEN};
      }
      @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
        display: none !important;
      }
    }
  `,
  CarouselNextArrow: styled(ArrowIcon)`
    && {
      color: ${COLORS.WHITE};
      width: 2rem;
      height: 2rem;
      stroke-width: 0.25;

      &:hover {
        color: ${COLORS.BLUE_CHALK_VIOLET};
      }
    }
  `,
  CarouselPrevArrow: styled(ArrowIcon)`
    && {
      transform: rotate(180deg);
      color: ${COLORS.WHITE};
      width: 2rem;
      height: 2rem;
      stroke-width: 0.25;

      &:hover {
        color: ${COLORS.BLUE_CHALK_VIOLET};
      }
    }
  `,
  CarouselDotsWrapper: styled.div`
    /*
    * React-slick put our custom CarouselDot in <li> and
    * add some classes to each element in the dots wrappers,
    * we need to override it
    */
    && {
      display: none;
      justify-content: center;
      li {
        width: 0.9rem;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: transparent;
      }
      .slick-active {
        color: ${COLORS.BOTTLE_GREEN};
      }
      @media screen and (max-width: ${IPAD_MAX_WIDTH}px) {
        display: flex;
      }
    }
  `,
  CarouselDot: styled.div`
    background-color: currentColor;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    border: solid ${COLORS.BOTTLE_GREEN} 0.05rem;
  `,

  Event: {
    InstaImage: styled.img`
      max-width: 384px;
      height: auto;
      object-fit: cover;
      aspect-ratio: 1;
      margin: auto;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        max-width: 318px;
      }
    `,
    Container: styled.div`
      align-items: center;
      background-color: ${COLORS.BEIGE};
      display: flex;
      flex-direction: column;
      padding: 50px 0px 60px 0px;
      justify-content: center;
      overflow: hidden;
      .slick-dots {
        position: static;
        margin-left: -13px;
      }
      .slick-dots li {
        margin-right: -13px;
        font-size: 7px;
      }

      .slick-dots li.slick-active button:before {
        color: ${COLORS.PINE_GREEN};
      }
      .slick-dots li button:before {
        color: ${COLORS.BLUE_CHALK_VIOLET};
      }
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        padding: 30px 0px 30px 0px;
      }
    `,
    Post: styled.div`
      width: fit-content;
      height: fit-content;
      margin: 0.3rem;
      position: relative;
      transition: filter 0.3s ease-out;
      &:hover {
        filter: brightness(0.85);
      }
    `,
    InstaLink: styled.a`
      cursor: pointer;
    `,
    Header: styled(S1)`
      margin-bottom: 5px;
    `,
    Hashtag: styled(H4)``,
    InstaIcon: styled(Instagram)`
      cursor: pointer;
      pointer-events: none;
      position: absolute;
      right: 16px;
      bottom: 10px;
      width: 32px;
      height: 32px;
      color: ${COLORS.WHITE};
    `,
  },
}
