import Slider from "react-slick"
import styled from "styled-components"
import { COLORS, MOBILE_MAX_WIDTH } from "../../styles"
import Card from "../card/card"
import { S2 } from "../common/text"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default {
  Container: styled.div`
    padding: 50px 32px;
    text-align: center;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding: 30px 0px;
    }
  `,

  Title: styled(S2)`
    text-transform: uppercase;
    margin-bottom: 50px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 30px;
    }
  `,

  Card: styled(Card)`
    text-align: left;
    margin: 0 18px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin: 0 auto;
    }
  `,
  CardsContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
  `,

  Slider: styled(Slider)`
    .slick-dots {
      position: static;
      margin-top: 20px;
      margin-right: 13px;
    }
    .slick-dots li.slick-active {
      color: ${COLORS.BOTTLE_GREEN};
    }
    .slick-dots li {
      margin: 0px -4px;
      color: ${COLORS.BLUE_CHALK_VIOLET};
    }
  `,
}
