import React from "react"
import Style from "./featuredItineraries.style"
import {
  ItineraryCardProps,
  getReducedDaysOpen,
} from "../explore/itineraryCard"
import Slider from "react-slick"
import { COLORS } from "../../styles"

interface ItineraryProps {
  title: string
  creator: string
  image: {
    fluid: any
    title: string
  }
  excerpt: string
  description: {
    description: string
  }
  daysOpen: Array<string>
  slug: string
}
interface Props {
  headline: string
  itineraries: ItineraryProps[]
  explorePageSlug: string
}

const sliderSettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  swipeToSlide: true,
  dots: true,
  arrows: false,
}
const FeaturedItinerary = ({
  image,
  daysOpen,
  title,
  creator,
  excerpt,
  itinerarySlug,
  explorePageSlug,
}: ItineraryCardProps) => (
  <Style.Itinerary.Container>
    <Style.Itinerary.Container_1>
      <Style.Itinerary.Days $textColor="white">
        {daysOpen && getReducedDaysOpen(daysOpen)}
      </Style.Itinerary.Days>
      <Style.Itinerary.Title>{title}</Style.Itinerary.Title>
      <Style.Itinerary.Creator $textColor={COLORS.HOLLY_GREEN}>
        BY {creator}
      </Style.Itinerary.Creator>
      <Style.Itinerary.Description $textColor={COLORS.CHARCOAL}>
        {excerpt}
      </Style.Itinerary.Description>
      <Style.Itinerary.CTA to={`/${explorePageSlug}/${itinerarySlug}`}>
        View Itinerary
      </Style.Itinerary.CTA>
    </Style.Itinerary.Container_1>
    {image && <Style.Itinerary.Image fluid={image.fluid} alt={image.title} />}
  </Style.Itinerary.Container>
)

const FeaturedItineraries = ({
  headline,
  itineraries,
  explorePageSlug,
}: Props) => (
  <Style.Container>
    <Style.Title>{headline}</Style.Title>

    <Slider {...sliderSettings}>
      {itineraries.map(
        ({ title, image, creator, excerpt, daysOpen, slug }, index) => {
          return (
            <FeaturedItinerary
              key={index}
              title={title}
              image={image}
              daysOpen={daysOpen}
              creator={creator}
              excerpt={excerpt}
              itinerarySlug={slug}
              explorePageSlug={explorePageSlug}
            />
          )
        }
      )}
    </Slider>
  </Style.Container>
)

export default FeaturedItineraries
