import styled from "styled-components"
import Img from "gatsby-image"

import { CTA } from "../common/cta"
import { H5, T1 } from "../common/text"
import { IPHONE5_MAX_WIDTH, MOBILE_MAX_WIDTH } from "../../styles"

export default {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 370px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      width: 317px;
    }
    @media screen and (max-width: ${IPHONE5_MAX_WIDTH}px) {
      width: 275px;
    }
  `,
  Image: styled(Img)`
    width: 100%;
    height: 248px;
    margin-bottom: 15px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: 212px;
    }
  `,

  Title: styled(H5)`
    margin-bottom: 5px;
  `,
  Content: styled(T1)`
    margin-bottom: 20px;
  `,

  Link: styled(CTA)`
    text-decoration: none;
    text-transform: capitalize;
  `,
}
