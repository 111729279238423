import styled, { css } from "styled-components"
import Img from "gatsby-image"
import { COLORS, FONTS, MOBILE_MAX_WIDTH, Z_INDEXES } from "../styles"
import { HEADER_HEIGHT } from "./header/header.style"

export default {
  CategoriesContainer: styled.div`
    background-color: ${COLORS.HOLLY_GREEN};
    display: flex;
    height: calc(100vh - ${HEADER_HEIGHT});
    justify-content: space-around;
    padding: 1.9rem 0 4rem 0;

    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      height: calc(100vh - ${HEADER_HEIGHT});
      padding-bottom: 4rem;
    }
  `,
  CategoryBackground: styled.div<{
    isImageDisplayed: boolean
    isAnimate: boolean
  }>`
    background-color: ${COLORS.HOLLY_GREEN};
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    left: 0;
    position: absolute;
    top: 0;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    width: 100%;
    z-index: ${Z_INDEXES.INDEX_CATEGORY_BACKGROUND};

    ${props =>
      props.isImageDisplayed &&
      css`
        background-color: transparent;
      `}

    ${props =>
      !props.isAnimate &&
      css`
        &:hover {
          background-color: transparent;
        }
      `}
  `,
  CategoryContainer: styled.div<{ isAnimate: boolean }>`
    flex-grow: 1;
    height: 100%;
    position: relative;
    text-decoration: none;

    ${props =>
      !props.isAnimate &&
      css`
        border-right: thin solid rgba(255, 255, 255, 0.5);
      `}

    &:last-child {
      border: 0;
    }
  `,
  Category: styled.div<{ isAnimate: boolean }>`
    background-color: ${COLORS.HOLLY_GREEN};
    color: ${COLORS.BLUE_CHALK_VIOLET};
    font-family: ${FONTS.FIFTH_AVE_FONT_FAMILY};
    font-size: 30px;
    height: fit-content;
    letter-spacing: ${FONTS.FIFTH_AVE_FONT_SPACING};
    padding: 0.5rem 1rem;
    width: 3.5rem;
    text-align: right;
    text-orientation: upright;
    text-transform: uppercase;
    line-height: 1.17;
    letter-spacing: 1.88px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}) {
      font-size: 28px;
    }
    &:first-child {
      padding-top: 1rem;
    }
    &:last-child {
      padding-bottom: 1rem;
    }
    ${props =>
      props.isAnimate &&
      css`
        display: none;
      `}
  `,
  BackgroundImage: styled(Img)`
    height: 100%;
    width: 100%;
    z-index: ${Z_INDEXES.INDEX_CATEGORY_BACKGROUND_IMAGE};
  `,
}
