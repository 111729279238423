import Img from "gatsby-image"
import styled from "styled-components"
import { Media } from "../../Media"
import { MOBILE_MAX_WIDTH } from "../../styles"
import { H4, S1, S2 } from "../common/text"
import { AutoLink } from "../link"

export default {
  Title: styled(S2)`
    text-align: center;
    padding-top: 50px;
    height: 124px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      padding-top: 29px;
      height: 79px;
    }
  `,

  TickerContainer: styled(Media)`
    margin-bottom: 50px;
    @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
      margin-bottom: 66px;
    }
  `,
  Card: {
    Container: styled.div`
      display: flex;
      flex-direction: column;
      margin-right: 35px;
      width: 656px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 325px;
        margin: 0 auto;
      }
    `,
    TopContainer: styled(AutoLink)`
      display: flex;
      flex-direction: row;
      height: 380px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        height: 345px;
      }
    `,
    Image: styled(Img)`
      height: 100%;
      width: 580px;
      margin-right: 11px;
      @media screen and (max-width: ${MOBILE_MAX_WIDTH}px) {
        width: 265;
        margin-right: 30px;
      }
    `,
    Date: styled(S1)`
      margin: 15px 0 5px 0;
    `,
    Title: styled(H4)`
      margin-bottom: 20px;
    `,
  },
}
