import { graphql } from "gatsby"
import React, { useMemo } from "react"
import HomePageCarousel from "../components/home-page/homePageCarousel"
import Layout from "../components/layout/layout"
import MenuFive, { ContentfulFiveMenu } from "../components/menu/menuFive"
import SEO from "../components/common/seo"
import FeaturedItineraries from "../components/home-page/featuredItineraries"
import HashtagInstaFeed, {
  InstagramPostNode,
} from "../components/instafeed/hashtagInstaFeed"
import { COLORS } from "../styles"
import NewsSection from "../components/home-page/newsSection"
import { DetailedArticle } from "../templates/news/news"
import { FluidObject } from "gatsby-image"

interface Props {
  data: {
    contentfulHomePage: {
      title: string
      description?: string
      newsSectionTitle: string
      featuredItinerariesTitle: string
      featuredItineraries: {
        title: string
        creator: string
        image: {
          fluid: FluidObject
          title: string
        }
        excerpt: string
        description: {
          description: string
        }
        slug: string
        daysOpen: Array<string>
      }[]
      homePageHashtag: string
      instagramSectionTitle: string
    }
    allContentfulCategory: {
      edges: {
        node: {
          id: string
          name: string
          slug: string
          order: number
          image: {
            title: string
            fluid: any
          }
        }
      }[]
    }
    contentfulExplorePage: {
      name: string
      slug: string
      publish: boolean
    }
    contentfulEventsPage: {
      title: string
      slug: string
      image: {
        fluid: FluidObject
        title: string
      }
    }
    allContentfulEvents: {
      edges: {
        node: {
          id: string
          title: string
          date: Date
          endDate: Date
          customDate: string
          slug: string
          category: {
            id: string
            name: string
          }
          content: {
            content: string
          }
          image: {
            fluid: FluidObject
            title: string
          }
          externalLink: string
          ongoing: boolean
        }
      }[]
    }
    allContentfulNews: {
      edges: {
        node: DetailedArticle
      }[]
    }
    contentfulFiveMenu: ContentfulFiveMenu
    allInstagramPostNode: {
      nodes: InstagramPostNode[]
    }
  }
}

const IndexPage = ({ data }: Props) => {
  const {
    title,
    description,
    featuredItinerariesTitle,
    featuredItineraries,
    homePageHashtag,
    instagramSectionTitle,
    newsSectionTitle,
  } = data.contentfulHomePage
  const explorePageSlug = data.contentfulExplorePage.slug
  const eventPageSlug = data.contentfulEventsPage.slug
  const events = data.allContentfulEvents.edges.map(edge => edge.node)
  const newsCards = useMemo(
    () => data.allContentfulNews.edges.map(edge => edge.node),
    [data]
  )

  return (
    <Layout>
      <SEO title={title} description={description} />
      <MenuFive data={data.contentfulFiveMenu} />
      {events && <HomePageCarousel events={events} slug={eventPageSlug} />}
      {featuredItineraries && (
        <FeaturedItineraries
          headline={featuredItinerariesTitle}
          itineraries={featuredItineraries}
          explorePageSlug={explorePageSlug}
        />
      )}
      <NewsSection sectionTitle={newsSectionTitle} cards={newsCards} />
      <HashtagInstaFeed
        color={COLORS.CHALK_VIOLET}
        hashtag={homePageHashtag}
        experienceSharingTitle={instagramSectionTitle}
        posts={data.allInstagramPostNode.nodes}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    contentfulHomePage(instagramSectionTitle: { ne: "Dummy" }) {
      newsSectionTitle
      title
      description
      featuredItinerariesTitle
      featuredItineraries {
        image {
          title
          fluid(maxWidth: 600) {
            ...GatsbyContentfulFluid
          }
        }
        title
        creator
        excerpt
        daysOpen
        slug
      }
      homePageHashtag
      instagramSectionTitle
    }
    allContentfulCategory {
      edges {
        node {
          id
          name
          slug
          order
          image {
            title
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    contentfulExplorePage {
      name
      slug
      publish
    }
    contentfulEventsPage {
      title
      slug
      image {
        title
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulEvents(
      sort: { fields: [order, date], order: [ASC, DESC] }
      filter: { parentEvent: { title: { eq: null } } }
    ) {
      edges {
        node {
          id
          title
          date
          endDate
          customDate
          eventCategory {
            id
            title
          }
          content {
            content
          }
          image {
            title
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          externalLink
          ongoing
          slug
        }
      }
    }
    allContentfulNews(
      limit: 3
      sort: { fields: [order, createdAt], order: [ASC, DESC] }
    ) {
      edges {
        node {
          id
          title
          content {
            content
          }
          image {
            title
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid
            }
          }
          externalLink
        }
      }
    }
    contentfulFiveMenu {
      firstMenuItemText
      firstMenuItemUrl
      firstMenuItemIsExternal
      firstMenuItemImage {
        title
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      secondMenuItemText
      secondMenuItemUrl
      secondMenuItemIsExternal
      secondMenuItemImage {
        title
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      thirdMenuItemText
      thirdMenuItemUrl
      thirdMenuItemIsExternal
      thirdMenuItemImage {
        title
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      fourthMenuItemText
      fourthMenuItemUrl
      fourthMenuItemIsExternal
      fourthMenuItemImage {
        title
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      fifthMenuItemText
      fifthMenuItemUrl
      fifthMenuItemIsExternal
      fifthMenuItemImage {
        title
        fluid(maxWidth: 1200, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allInstagramPostNode(
      filter: { type: { regex: "/^((?!placeholder).)*$/" } }
    ) {
      nodes {
        id
        link
        type
        hashtag
        altText: text
        imageUrl
      }
    }
  }
`
