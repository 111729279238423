import dayjs from "dayjs"
import { FluidObject } from "gatsby-image"
import { isNull } from "lodash"
import React, { FunctionComponent, useRef } from "react"
import { CTA } from "../common/cta"
import { H2 } from "../common/text"
import Ticker from "../common/ticker"
import Style from "./homePageCarousel.style"
import Carousel from "nuka-carousel"
import { PagingDots } from "../common/dots.style"
import { Link } from "gatsby"

export interface Event {
  id: string
  title: string
  date: Date
  slug: string
  endDate: Date | null
  customDate: string
  category: {
    id: string
    name: string
  }
  content: {
    content: string
  }
  image: {
    fluid: FluidObject
    title: string
  }
  externalLink: string
  ongoing: boolean
}

interface Props {
  events: Event[]
  slug: string
}

interface CarouselItemProps {
  event: Event
  categorySlug: string
}

const CarouselItem: FunctionComponent<CarouselItemProps> = ({
  event,
  categorySlug,
}) => {
  return (
    <Style.Card.Container>
      <Style.Card.TopContainer to={`/${categorySlug}/${event.slug}`}>
        <Style.Card.Image fluid={event.image.fluid} alt={event.image.title} />
      </Style.Card.TopContainer>

      <Style.Card.Date>	
        {event.customDate ||	
          (event.endDate && dayjs(event.endDate).format("MMMM D, YYYYY"))}	
      </Style.Card.Date>

      <Style.Card.Title>{event.title}</Style.Card.Title>
      <CTA to={`/${categorySlug}/${event.slug}`} id="ctaId">
        Learn More
      </CTA>
    </Style.Card.Container>
  )
}

const HomePageCarousel: FunctionComponent<Props> = ({ events, slug }) => {
  const globalComingSoonEvents = events
    .filter(
      event =>
        ((!event.ongoing && dayjs(event.date).isAfter(dayjs(new Date()))) ||
          (event.ongoing &&
            (isNull(event.endDate) ||
              dayjs(event.endDate).isAfter(dayjs(new Date()))))) &&
        !event.title.toLowerCase().includes("dummy")
    )
    .slice(0, 8)

  return (
    <>
      {globalComingSoonEvents.length !== 0 && (
        <>
          <Link to="/events" style={{ textDecoration: "none" }}>
            <Style.Title>EVENTS</Style.Title>
          </Link>
          <Style.TickerContainer greaterThanOrEqual="sm">
            <Ticker>
              {globalComingSoonEvents.map(event => (
                <CarouselItem
                  event={event}
                  categorySlug={slug}
                  key={event.slug}
                />
              ))}
            </Ticker>
          </Style.TickerContainer>
          <Style.TickerContainer lessThan="sm">
            <Carousel
              wrapAround
              heightMode="max"
              cellAlign="center"
              renderCenterRightControls={null}
              renderCenterLeftControls={null}
              renderBottomCenterControls={PagingDots}
              getControlsContainerStyles={key => {
                switch (key) {
                  case "BottomCenter":
                    return {
                      bottom: "-36px",
                    }
                  default:
                    return {}
                }
              }}
            >
              {globalComingSoonEvents.map(event => (
                <CarouselItem
                  event={event}
                  categorySlug={slug}
                  key={event.slug}
                />
              ))}
            </Carousel>
          </Style.TickerContainer>
        </>
      )}
    </>
  )
}

export default HomePageCarousel
