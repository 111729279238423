import { FluidObject } from "gatsby-image"
import React, { FunctionComponent, useEffect, useState } from "react"
import Style from "../index.style"
import CustomLink from "../link"

const sleep = (time: number) => new Promise(r => setTimeout(r, time))

const tempo = 500

export interface ContentfulFiveMenu {
  firstMenuItemText: string
  firstMenuItemUrl: string
  firstMenuItemIsExternal: boolean
  firstMenuItemImage: {
    fluid: FluidObject
    title?: string
  }

  secondMenuItemText: string
  secondMenuItemUrl: string
  secondMenuItemIsExternal: boolean
  secondMenuItemImage: {
    fluid: FluidObject
    title?: string
  }

  thirdMenuItemText: string
  thirdMenuItemUrl: string
  thirdMenuItemIsExternal: boolean
  thirdMenuItemImage: {
    fluid: FluidObject
    title?: string
  }

  fourthMenuItemText: string
  fourthMenuItemUrl: string
  fourthMenuItemIsExternal: boolean
  fourthMenuItemImage: {
    fluid: FluidObject
    title?: string
  }

  fifthMenuItemText: string
  fifthMenuItemUrl: string
  fifthMenuItemIsExternal: boolean
  fifthMenuItemImage: {
    fluid: FluidObject
    title?: string
  }
}

interface Props {
  data: ContentfulFiveMenu
}

interface MenuItem {
  text: string
  url: string
  isExternal: boolean
  image: {
    fluid: FluidObject
    title?: string
  }
}

const transformData = (data: ContentfulFiveMenu): MenuItem[] => {
  const firstMenuItem = {
    text: data.firstMenuItemText,
    url: data.firstMenuItemUrl,
    isExternal: data.firstMenuItemIsExternal,
    image: data.firstMenuItemImage,
  }
  const secondMenuItem = {
    text: data.secondMenuItemText,
    url: data.secondMenuItemUrl,
    isExternal: data.secondMenuItemIsExternal,
    image: data.secondMenuItemImage,
  }
  const thirdMenuItem = {
    text: data.thirdMenuItemText,
    url: data.thirdMenuItemUrl,
    isExternal: data.thirdMenuItemIsExternal,
    image: data.thirdMenuItemImage,
  }
  const fourthMenuItem = {
    text: data.fourthMenuItemText,
    url: data.fourthMenuItemUrl,
    isExternal: data.fourthMenuItemIsExternal,
    image: data.fourthMenuItemImage,
  }
  const fifthMenuItem = {
    text: data.fifthMenuItemText,
    url: data.fifthMenuItemUrl,
    isExternal: data.fifthMenuItemIsExternal,
    image: data.fifthMenuItemImage,
  }
  return [
    firstMenuItem,
    secondMenuItem,
    thirdMenuItem,
    fourthMenuItem,
    fifthMenuItem,
  ]
}

const MenuFive: FunctionComponent<Props> = ({ data }) => {
  const allItems = transformData(data)
  const [imagesLoaded, setImagesLoaded] = useState(allItems.map(() => false))
  const [imagesDisplayed, setImagesDisplayed] = useState<number[]>([])
  const [hasAnimationStarted, startAnimation] = useState(false)
  const [isAnimate, setAnimation] = useState(false)

  const setImageLoaded = (index: number) => {
    const imagesLoadedTemp = [...imagesLoaded]
    imagesLoadedTemp[index] = true
    setImagesLoaded(imagesLoadedTemp)
  }

  const animate = async () => {
    startAnimation(true)
    setAnimation(true)
    setImagesDisplayed([2])
    await sleep(tempo)

    setImagesDisplayed([1, 3])
    await sleep(tempo)

    setImagesDisplayed([0, 4])
    await sleep(tempo)

    setImagesDisplayed([0, 4])
    await sleep(10)

    setAnimation(false)
    setImagesDisplayed([Math.floor(Math.random() * allItems.length)])
  }

  useEffect(() => {
    const imagesAllLoaded = imagesLoaded.every(imageLoaded => imageLoaded)
    if (imagesAllLoaded && !hasAnimationStarted) {
      animate()
    }
  }, [imagesLoaded, hasAnimationStarted])

  return (
    <Style.CategoriesContainer>
      {allItems.map((menuItem: MenuItem, index) => (
        <Style.CategoryContainer
          key={index}
          onLoad={() => setImageLoaded(index)}
          isAnimate={isAnimate}
          onMouseEnter={() => setImagesDisplayed([index])}
          onMouseLeave={() => setImagesDisplayed([index])}
        >
          <CustomLink link={menuItem.url} isInternal={!menuItem.isExternal}>
            {menuItem.image && (
              <Style.BackgroundImage
                fluid={menuItem.image.fluid}
                alt={menuItem.image.title}
              />
            )}
            <Style.CategoryBackground
              isImageDisplayed={imagesDisplayed.includes(index)}
              isAnimate={isAnimate}
            >
              {menuItem.text.split(" ").map((sub: string, index: number) => (
                <Style.Category isAnimate={isAnimate} key={index}>
                  {sub}
                </Style.Category>
              ))}
            </Style.CategoryBackground>
          </CustomLink>
        </Style.CategoryContainer>
      ))}
    </Style.CategoriesContainer>
  )
}

export default MenuFive
