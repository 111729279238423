import React, { FunctionComponent } from "react"
import Styles from "./newsSection.style"
import { FluidObject } from "gatsby-image"
import { Media } from "../../Media"
import { Article } from "../../templates/news/news"
import { Link } from "gatsby"

interface Props {
  sectionName?: string
  sectionTitle: string
  cards: Article[]
}

const NewsSection: FunctionComponent<Props> = ({ sectionTitle, cards }) => {
  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    dots: true,
    arrows: false,
  }

  return (
    <Styles.Container>
      <Link to="/news" style={{ textDecoration: "none" }}>
        <Styles.Title $textColor="green">{sectionTitle}</Styles.Title>
      </Link>
      <Media lessThan="md">
        <Styles.Slider {...sliderSettings}>
          {cards.map(card => (
            <Styles.Card key={card.id} card={card} />
          ))}
        </Styles.Slider>
      </Media>
      <Media greaterThanOrEqual="md">
        <Styles.CardsContainer>
          {cards.map((card, index) => (
            <Styles.Card key={index} card={card} />
          ))}
        </Styles.CardsContainer>
      </Media>
    </Styles.Container>
  )
}

export default NewsSection
